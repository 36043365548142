import * as React from 'react';
import "../src/Styles/Banner.css"
import { expenseInitialState, expenseReducerName, expenseReducer } from './Shared/Store/Expense.reducer';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { IExpenseAppState } from './Shared/Store/Expense.types';
import { requestPortalDowntime } from './Shared/Store/Expense.actions';
import { sharedExpenseSagas } from './Shared/Store/Expense.sagas';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { Reducer } from 'redux';

const StaticBanner: React.FC = () => {
    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);

    const { isLoadingExpenseBanner, expensePortalMessage, isExpensePortalDown, receiveBannerHasError, displayBanner } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );
    React.useEffect(() => {
        {
            dispatch(requestPortalDowntime());
        }
    }, [dispatch])
    if (!displayBanner || receiveBannerHasError) {
        return null;
    }
    return (
        <>
            {(isExpensePortalDown && !isLoadingExpenseBanner && expensePortalMessage) && (
                <div className="warning-banner">
                    <span className="warning-icon">❌</span>
                    <span className="warning-message">
                        <span aria-label={expensePortalMessage} role="banner"
                            tabIndex={0}>{expensePortalMessage}</span>
                    </span>
                </div>

            )}
        </>
    )
};
export default StaticBanner;