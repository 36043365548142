import * as React from 'react';
import { usePageTitle } from '@micro-frontend-react/employee-experience/lib/usePageTitle';
import { Reducer } from 'redux';
import {
    updateShowSecondaryHeader,
    requestMyProfile,
    requestExpenseStatCounts,
} from '../../../Shared/Store/Expense.actions';
import { sharedExpenseSagas } from '../../../Shared/Store/Expense.sagas';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { expenseReducerName, expenseReducer, expenseInitialState } from '../../../Shared/Store/Expense.reducer';
import { QuickLinksCard } from './Cards/QuickLinksCard';
import * as Styled from './MainDashboardStyling';
import { TrainingMaterialsCard } from './Cards/TrainingMaterialsCard';
import { IExpenseAppState } from '../../../Shared/Store/Expense.types';
import { AutomatedExpensesCard } from './Cards/AutomatedExpensesCard';
import { SubmittedExpensesCard } from './Cards/SubmittedExpensesCard';
import { ProcessingExpensesCard } from './Cards/ProcessingExpensesCard';
import { ApprovedExpensesCard } from './Cards/ApprovedExpensesCard';
import { ReceiptUploadCard } from './Cards/ReceiptUploadCard';
import StaticBanner from '../../../banner';

export function MainDashboardPage(): React.ReactElement {
    usePageTitle(`Home - ${__APP_NAME__}`);

    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);
    const { useSelector, telemetryClient, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const { expenseCounts, profile } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );

    React.useEffect(() => {
        dispatch(updateShowSecondaryHeader(false));
        if (!expenseCounts) {
            dispatch(requestExpenseStatCounts('Dashboard'));
        }
    }, [dispatch]);

    const expenseTool = profile ? profile.expenseTool : 'MYEXPENSE';

    return (
        <>
            <StaticBanner/>
        <Styled.DashboardContainer>
            <Styled.DashboardTitle>Dashboard</Styled.DashboardTitle>
            <Styled.DashboardRow>
                <QuickLinksCard />
                <AutomatedExpensesCard />
                <Styled.DashboardCol>
                    <SubmittedExpensesCard />
                    <ApprovedExpensesCard />
                </Styled.DashboardCol>
                <Styled.DashboardCol>
                    <ProcessingExpensesCard />
                    <ReceiptUploadCard />
                </Styled.DashboardCol>
                {expenseTool === 'MYEXPENSE' && <TrainingMaterialsCard />}
            </Styled.DashboardRow>
            </Styled.DashboardContainer>
        </>
    );
}
