//import { OperationType } from '../../Components/Pages/ManageDelegates/ManageDelegatesConstants';
import { IProfile, IGraphPhoto, IStats, IFeaturesIntroductionStep, IExpenseDelegateDetails } from './Expense.types';

//remove later
enum OperationType {
    add = 'add',
    patch = 'patch',
    delete = 'delete',
}

export enum ExpenseActionType {
    REQUEST_MY_PROFILE = 'REQUEST_MY_PROFILE',
    RECEIVE_MY_PROFILE = 'RECEIVE_MY_PROFILE',
    FAILED_PROFILE = 'FAILED_PROFILE',
    REQUEST_MY_SUMMARY = 'REQUEST_MY_SUMMARY',
    RECEIVE_MY_SUMMARY = 'RECEIVE_MY_SUMMARY',
    UPDATE_PANEL_STATE = 'UPDATE_PANEL_STATE',
    FAILED_SUMMARY = 'FAILED_SUMMARY',
    SET_DETAILS_DEFAULT_VIEW = 'SET_DETAILS_DEFAULT_VIEW',
    TOGGLE_DETAILS_SCREEN_MAX = 'TOGGLE_DETAILS_SCREEN_MAX',
    UPDATE_GROUPED_SUMMARY = 'UPDATE_GROUPED_SUMMARY',
    SET_CONTINUATION_TOKEN = 'SET_CONTINUATION_TOKEN',
    SET_CONTINUATION_TOKEN_DRAFT = 'SET_CONTINUATION_TOKEN_DRAFT',
    UPDATE_SHOW_SECONDARY_HEADER = 'UPDATE_SHOW_SECONDARY_HEADER',
    UPDATE_SETTINGS_PAGE_SHOWING = 'UPDATE_SETTINGS_PAGE_SHOWING',

    REQUEST_FILTERED_USERS = 'REQUEST_FILTERED_USERS',
    RECEIVE_FILTERED_USERS = 'RECEIVE_FILTERED_USERS',
    UPDATE_USER_ALIAS = 'UPDATE_USER_ALIAS',
    TOGGLE_TEACHING_BUBBLE_VISIBILITY = 'TOGGLE_TEACHING_BUBBLE_VISIBILITY',
    UPDATE_TEACHING_STEP = 'UPDATE_TEACHING_STEP',

    UPDATE_DROPDOWN_HASERROR = 'UPDATE_DROPDOWN_HASERROR',
    UPDATE_PEOPLEPICKER_HASERROR = 'UPDATE_PEOPLEPICKER_HASERROR',
    UPDATE_EXPENSE_AUTOMATION_HASERROR = 'UPDATE_EXPENSE_AUTOMATION_HASERROR',

    SAVE_USER_PREFERENCES = 'SAVE_USER_PREFERENCES',
    SAVE_USER_PREFERENCES_SUCCESS = 'SAVE_USER_PREFERENCES_SUCCESS',
    SAVE_USER_PREFERENCES_FAILED = 'SAVE_USER_PREFERENCES_FAILED',
    REQUEST_USER_PREFERENCES = 'REQUEST_USER_PREFERENCES',
    RECEIVE_USER_PREFERENCES = 'RECEIVE_USER_PREFERENCES',
    FAILED_USER_PREFERENCES = 'FAILED_USER_PREFERENCES',
    CLEAR_USER_PREFERENCES_API_MESSAGES = 'CLEAR_USER_PREFERENCES_API_MESSAGES',
    RECEIVE_SUBMITTER_IMAGES = 'RECEIVE_SUBMITTER_IMAGES',
    REQUEST_MY_DRAFTS = 'REQUEST_MY_DRAFTS',
    UPDATE_DELETED_REPORTS = 'UPDATE_DELETED_REPORTS',
    UPDATE_SUBMITTED_REPORTS = 'UPDATE_SUBMITTED_REPORTS',
    REQUEST_EXPENSE_STAT_COUNTS = 'REQUEST_EXPENSE_STAT_COUNTS',
    RECEIVE_EXPENSE_STAT_COUNTS = 'RECEIVE_EXPENSE_STAT_COUNTS',
    FAILED_EXPENSE_STAT_COUNTS = 'FAILED_EXPENSE_STAT_COUNTS',
    UPDATE_FILTER_VALUE = 'UPDATE_FILTER_VALUE',
    UPDATE_IS_COMING_FROM_DASHBOARD = 'UPDATE_IS_COMING_FROM_DASHBOARD',
    UPDATE_DELEGATE_TYPE = 'UPDATE_DELEGATE_TYPE',
    REQUEST_DELEGATES = 'REQUEST_DELEGATES',
    RECEIVE_DELEGATES = 'RECEIVE_DELEGATES',
    RECEIVE_DELEGATE_FAILED = 'RECEIVE_DELEGATE_FAILED',
    MODIFY_DELEGATE = 'MODIFY_DELEGATE',
    MODIFY_DELEGATE_SUCCESS = 'MODIFY_DELEGATE_SUCCESS',
    MODIFY_DELEGATE_FAILED = 'MODIFY_DELEGATE_FAILED',
    HIDE_DELEGATE_DIALOG = 'HIDE_DELEGATE_DIALOG',
    REQUEST_PORTAL_DOWNTIME = 'REQUEST_PORTAL_DOWNTIME',
    RECEIVE_PORTAL_DOWNTIME = 'RECEIVE_PORTAL_DOWNTIME',
    RECEIVE_PORTAL_DOWNTIME_FAILED = 'RECEIVE_PORTAL_DOWNTIME_FAILED'

}

export type ExpenseAction =
    | IRequestProfileAction
    | IReceiveProfileAction
    | IFailedProfileAction
    | IRequestSummaryAction
    | IReceiveSummaryAction
    | IFailedSummaryAction
    | IUpdatePanelState
    | IDetailsDefaultView
    | IToggleDetailsScreenMax
    | IUpdateGroupedSummary
    | ISetContinuationToken
    | ISetContinuationTokenDraft
    | IUpdateShowSecondaryHeader
    | IUpdateSettingsPageShowing
    | IRequestFilteredUsersAction
    | IReceiveFilteredUsersAction
    | IUpdateUserAlias
    | IToggleTeachingBubbleVisibility
    | IUpdateTeachingStep
    | IRequestUserPreferences
    | IReceiveUserPreferences
    | IUpdateDropdownHasError
    | IUpdatePeoplePickerHasError
    | IReceiveSubmitterImages
    | IRequestExpenseCountStats
    | IReceiveExpenseCountStats
    | IFailedExpenseCountStats
    | IRequestDrafts
    | IUpdateDeletedReports
    | IUpdateSubmittedReports
    | ISaveUserPreferences
    | ISaveUserPreferencesSuccess
    | ISaveUserPreferencesFailed
    | IUpdateFilterValue
    | IUpdateIsComingFromDashboard
    | IRequestDelegatesAction
    | IReceiveDelegatesAction
    | IReceiveDelegateActionFailed
    | IModifyDelegateAction
    | IModifyDelegateActionSuccess
    | IModifyDelegateActionFailed
    | IHideDelegateDialog
    | IRequestPortalDowntime
    | IReceivePortalDowntime
    | IReceivePortalDowntimeFailed;

export interface ISetContinuationToken {
    type: ExpenseActionType.SET_CONTINUATION_TOKEN;
    continuationToken: string;
}

export interface ISetContinuationTokenDraft {
    type: ExpenseActionType.SET_CONTINUATION_TOKEN_DRAFT;
    continuationTokenDraft: string;
}

export interface IUpdateGroupedSummary {
    type: ExpenseActionType.UPDATE_GROUPED_SUMMARY;
    summaryGroupedBy: string;
}

export interface IToggleDetailsScreenMax {
    type: ExpenseActionType.TOGGLE_DETAILS_SCREEN_MAX;
}

export interface IDetailsDefaultView {
    type: ExpenseActionType.SET_DETAILS_DEFAULT_VIEW;
    defaultView: string;
}

export interface IRequestProfileAction {
    type: ExpenseActionType.REQUEST_MY_PROFILE;
    featureName: string;
}

export interface IReceiveProfileAction {
    type: ExpenseActionType.RECEIVE_MY_PROFILE;
    profile: IProfile;
}

export interface IFailedProfileAction {
    type: ExpenseActionType.FAILED_PROFILE;
    profileErrorMessage: string;
}

export interface IRequestSummaryAction {
    type: ExpenseActionType.REQUEST_MY_SUMMARY;
    continuationToken: string;
    continuationTokenDraft: string;
    firstCall: boolean;
    featureName: string;
    draftsOnly?: boolean;
}

export interface IReceiveSummaryAction {
    type: ExpenseActionType.RECEIVE_MY_SUMMARY;
    expenseData: any;
    isFirstCall?: boolean;
}

export interface IFailedSummaryAction {
    type: ExpenseActionType.FAILED_SUMMARY;
    summaryErrorMessage: string;
}

export interface IUpdatePanelState {
    type: ExpenseActionType.UPDATE_PANEL_STATE;
    isOpen: boolean;
}

export interface IUpdateShowSecondaryHeader {
    type: ExpenseActionType.UPDATE_SHOW_SECONDARY_HEADER;
    showSecondaryHeader: boolean;
}

export interface IUpdateSettingsPageShowing {
    type: ExpenseActionType.UPDATE_SETTINGS_PAGE_SHOWING;
    settingsPageShowing: string;
}

export interface IRequestFilteredUsersAction {
    type: ExpenseActionType.REQUEST_FILTERED_USERS;
    filterText: string;
}

export interface IReceiveFilteredUsersAction {
    type: ExpenseActionType.RECEIVE_FILTERED_USERS;
    filteredUsers: object[];
}

export interface IUpdatePeoplePickerHasError {
    type: ExpenseActionType.UPDATE_PEOPLEPICKER_HASERROR;
    peoplePickerHasError: boolean;
}

export interface IUpdateUserAlias {
    type: ExpenseActionType.UPDATE_USER_ALIAS;
    userAlias: string;
    userName: string;
}
export interface IToggleTeachingBubbleVisibility {
    type: ExpenseActionType.TOGGLE_TEACHING_BUBBLE_VISIBILITY;
}
export interface IUpdateTeachingStep {
    type: ExpenseActionType.UPDATE_TEACHING_STEP;
    newStep: IFeaturesIntroductionStep;
}

export interface ISaveUserPreferencesSuccess {
    type: ExpenseActionType.SAVE_USER_PREFERENCES_SUCCESS;
    responseMessages: string[];
}

export interface ISaveUserPreferencesFailed {
    type: ExpenseActionType.SAVE_USER_PREFERENCES_FAILED;
    responseMessages: string[];
}

export interface ISaveUserPreferences {
    type: ExpenseActionType.SAVE_USER_PREFERENCES;
    userPreferences?: ISaveUserPreferencesPayload;
}

export interface ISaveUserPreferencesPayload {
    submitterDelegates: string[];
    approverDelegates: string[];
    finalApprover: string;
    interimApprovers: string[];
    bundlingFrequency: string;
    pauseAutomation: boolean;
    feedback: string;
    interimApproverNames: string[];
    finalApproverName: string;
}

export interface IRequestUserPreferences {
    type: ExpenseActionType.REQUEST_USER_PREFERENCES;
}

export interface IReceiveUserPreferences {
    type: ExpenseActionType.RECEIVE_USER_PREFERENCES;
    userPreferences: IUserPreferencesResponseObj;
}

export interface IUserPreferencesResponseObj {
    defaultInterimApprovers: string[];
    defaultFinalApprover: string;
    defaultInterimApproverNames: string[];
    defaultFinalApproverName: string;
    bundlingFrequency: string;
    isAutomationDisabled: boolean;
    feedback: string;
}

export interface IUpdateDropdownHasError {
    type: ExpenseActionType.UPDATE_DROPDOWN_HASERROR;
    dropdownHasError: boolean;
}

export interface IUpdateExpenseAutomationHasError {
    type: ExpenseActionType.UPDATE_EXPENSE_AUTOMATION_HASERROR;
    choiceGroupHasError: boolean;
}
export interface IReceiveSubmitterImages {
    type: ExpenseActionType.RECEIVE_SUBMITTER_IMAGES;
    submitterImages: IGraphPhoto[];
}

export interface IRequestDrafts {
    type: ExpenseActionType.REQUEST_MY_DRAFTS;
}

export interface IUpdateDeletedReports {
    type: ExpenseActionType.UPDATE_DELETED_REPORTS;
    reportId: string;
}

export interface IUpdateSubmittedReports {
    type: ExpenseActionType.UPDATE_SUBMITTED_REPORTS;
    reportId: string;
}

export interface IRequestExpenseCountStats {
    type: ExpenseActionType.REQUEST_EXPENSE_STAT_COUNTS;
    featureName: string;
}

export interface IReceiveExpenseCountStats {
    type: ExpenseActionType.RECEIVE_EXPENSE_STAT_COUNTS;
    expenseCounts: IStats;
}

export interface IFailedExpenseCountStats {
    type: ExpenseActionType.FAILED_EXPENSE_STAT_COUNTS;
    errorMessage: string;
    defaultCounts: IStats;
}

export interface IUpdateFilterValue {
    type: ExpenseActionType.UPDATE_FILTER_VALUE;
    filterValue: string;
}

export interface IUpdateIsComingFromDashboard {
    type: ExpenseActionType.UPDATE_IS_COMING_FROM_DASHBOARD;
    isComingFromDashboard: boolean;
}

export interface IRequestDelegatesAction {
    type: ExpenseActionType.REQUEST_DELEGATES;
    delegateType: string;
}

export interface IReceiveDelegatesAction {
    type: ExpenseActionType.RECEIVE_DELEGATES;
    delegatesList: IExpenseDelegateDetails[];
}

export interface IReceiveDelegateActionFailed {
    type: ExpenseActionType.RECEIVE_DELEGATE_FAILED;
    receiveDelegateListErrorMessage: string;
}

export interface IModifyDelegateAction {
    type: ExpenseActionType.MODIFY_DELEGATE;
    delegate: IExpenseDelegateDetails;
    operation: OperationType;
}

export interface IModifyDelegateActionSuccess {
    type: ExpenseActionType.MODIFY_DELEGATE_SUCCESS;
    modifyDelegateSuccessMessage: string;
}

export interface IModifyDelegateActionFailed {
    type: ExpenseActionType.MODIFY_DELEGATE_FAILED;
    modifyDelegateErrorMessage: string;
}

export interface IHideDelegateDialog {
    type: ExpenseActionType.HIDE_DELEGATE_DIALOG;
}
export interface IRequestPortalDowntime {
    type: ExpenseActionType.REQUEST_PORTAL_DOWNTIME;
}
export interface IReceivePortalDowntime {
    type: ExpenseActionType.RECEIVE_PORTAL_DOWNTIME;
    isExpensePortalDown: boolean;
    disableLinks: boolean;
    expensePortalMessage: string;
}
export interface IReceivePortalDowntimeFailed {
    type: ExpenseActionType.RECEIVE_PORTAL_DOWNTIME_FAILED;
    receiveBannerErrorMessage: string;
}