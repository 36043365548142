import * as React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Reducer } from 'redux';
import { usePageTitle } from '@micro-frontend-react/employee-experience/lib/usePageTitle';
import { usePageTracking } from '@micro-frontend-react/employee-experience/lib/usePageTracking';
import { ExpensePage } from '../../ExpenseSummaryCards/ExpensePage';
import { UserEvent, EventType, UsageEventName } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';
import {
    requestMyProfile,
    updateShowSecondaryHeader,
    requestExpenseStatCounts,
} from '../../../Shared/Store/Expense.actions';
import { sharedExpenseSagas } from '../../../Shared/Store/Expense.sagas';
import { submitExpenseDetailsFailed } from '../../ExpenseDetails/Details.actions';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { expenseReducerName, expenseReducer, expenseInitialState } from '../../../Shared/Store/Expense.reducer';
import { IExpenseAppState } from '../../../Shared/Store/Expense.types';
import StaticBanner from '../../../banner';

export const sampleAppUserEvent: Partial<UserEvent> = {
    feature: 'OneExpense',
    type: EventType.User,
};

interface IExpenseDashboardPageProps {
    match: {
        params: {
            status: string;
            reportId: string;
        };
    };
    history: any;
    location: any;
}

export function ExpenseDashboardPage(props: IExpenseDashboardPageProps): React.ReactElement {
    usePageTitle(`Expenses - ${__APP_NAME__}`);
    const pageEvent = { ...sampleAppUserEvent, subFeature: 'ExpenseDashboard', eventName: UsageEventName.PageLoad };
    //usePageTracking(pageEvent);
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);
    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const { profile, isLoadingProfile, expenseCounts } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    React.useEffect(() => {
        if (!profile && !isLoadingProfile) {
            dispatch(requestMyProfile('Expenses'));
        }
        if (!expenseCounts) {
            dispatch(requestExpenseStatCounts('Expenses'));
        }
        dispatch(submitExpenseDetailsFailed(null));
        dispatch(updateShowSecondaryHeader(true));
    }, [dispatch]);

    return (
        <div>
            <StaticBanner/>
            <Stack>
                <Stack.Item>
                    <h1 style={{ visibility: 'hidden', height: 0 }}>Expense Reports</h1>
                    <ExpensePage
                        winHeight={dimensions.height}
                        queryReportId={props?.match?.params?.reportId}
                        queryStatus={props?.match?.params?.status}
                        routerLocation={props?.location}
                        routerHistory={props?.history}
                        windowWidth={dimensions.width}
                    />
                </Stack.Item>
            </Stack>
        </div>
    );
}
